

























































































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      language: "language",
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      url: "url",
      oss: "oss"
    }),
    communityImages(): string[] {
      return [
        this.oss.am_emptiness_phonecase_swiper_03,
        this.oss.am_emptiness_phonecase_swiper_19,
        this.oss.am_emptiness_phonecase_swiper_20,
        this.oss.am_emptiness_phonecase_swiper_05,
        this.oss.am_emptiness_phonecase_swiper_06,
        this.oss.am_emptiness_phonecase_swiper_21,
        this.oss.am_emptiness_phonecase_swiper_08,
        this.oss.am_emptiness_phonecase_swiper_11,
        this.oss.am_emptiness_phonecase_swiper_12,
        this.oss.am_emptiness_phonecase_swiper_18,
        this.oss.am_emptiness_phonecase_swiper_17,
        this.oss.am_emptiness_phonecase_swiper_13
      ];
    },
    communityNames(): string[] {
      return this.isCN
        ? [
            "by Tse 蟹蟹",
            "by Tse 蟹蟹",
            "by Tse 蟹蟹",
            "by 不晚",
            "by 不晚",
            "by 是那那子吖",
            "by 是那那子吖",
            "by 是梨五五呀",
            "by 是梨五五呀",
            "by 一坨肥肉",
            "by Guzy 13",
            "by Guzy 13",
            "by IvanLauuu",
            "by IvanLauuu",
            "by IvanLauuu",
            "by luuerxx ",
            "by luuerxx ",
            "by 夹克2",
            "by ZZY",
            "by ZZY",
            "by Shipike",
            "by Shipike",
            "by Shipike",
            "by KK",
            "by KK",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by 沙拉",
            "by 沙拉",
            "by 沙拉",
            "by 沙拉",
            "by 沙拉",
            "by 沙拉",
            "by 鬼鬼",
            "by 鬼鬼",
            "by 肌肉肥羊",
            "by 肌肉肥羊",
            "by 长安"
          ]
        : [
            "by Qingkong",
            "by Qingkong",
            "by Qingkong",
            "by Qingkong",
            "by Qingkong",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by Jiake2",
            "by Jiake2",
            "by Jiake2",
            "by Jiake2",
            "by ZZY",
            "by ZZY",
            "by Shipike",
            "by Shipike",
            "by Shipike",
            "by KK",
            "by KK",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by Shala",
            "by Shala",
            "by Shala",
            "by Shala",
            "by Shala",
            "by Shala",
            "by Ghost",
            "by Ghost",
            "by Jirou Feiyang",
            "by Jirou Feiyang",
            "by Changan"
          ];
    }
  },
  methods: {}
});
